import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@ib-env/environment';
import {
  IAuthUser,
  IAuthUserCreate,
  IAuthUserWithJwt,
} from '@ib-shared/interfaces/auth-user.intf';
import {
  IInvestorRegistrDocumentCreate,
  IJwtTokens,
  INVESTOR_SIGN_UP_STEPS,
} from 'credebt-shared';
import { delay, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  public readonly REFRESH_TOKEN_URL = '/api/auth/refresh';
  constructor(private http: HttpClient) {}

  public login(payload: {
    email: string;
    password: string;
  }): Observable<IAuthUserWithJwt> {
    return this.http.post<IAuthUserWithJwt>(
      `${environment.apiUrl}/api/auth/loginInvestor`,
      payload,
    );
  }

  public forgotPassword(payload: { email: string }): Observable<boolean> {
    return this.http.post<boolean>(
      `${environment.apiUrl}/api/auth/forgotPassword`,
      payload,
    );
  }

  public resetPassword(payload: {
    code: string;
    newPassword: string;
  }): Observable<boolean> {
    return this.http.post<boolean>(
      `${environment.apiUrl}/api/auth/resetPassword`,
      payload,
    );
  }

  public checkResetPasswordCode(v: { code: string }): Observable<boolean> {
    return this.http
      .post<boolean>(`${environment.apiUrl}/api/auth/checkResetPasswordCode`, v)
      .pipe(delay(environment.production ? 0 : 1000));
  }

  public signUp(
    payload: IAuthUserCreate,
    file: File,
  ): Observable<IAuthUserWithJwt> {
    const formData = new FormData();
    Object.entries(payload).forEach(i => {
      if (i[1]) {
        formData.append(i[0], i[1]);
      }
    });
    formData.append('file', file);
    return this.http.post<IAuthUserWithJwt>(
      `${environment.apiUrl}/api/auth/save-investor`,
      formData,
    );
  }

  public refreshToken(refresh: string): Observable<IJwtTokens> {
    return this.http.post<IJwtTokens>(
      `${environment.apiUrl}${this.REFRESH_TOKEN_URL}`,
      { refreshToken: refresh },
    );
  }

  public getMe(): Observable<IAuthUser> {
    return this.http.get<IAuthUser>(`${environment.apiUrl}/api/auth/me`);
  }

  public signUpMarketing(
    step: INVESTOR_SIGN_UP_STEPS,
    stepPayload: Partial<IAuthUserCreate>,
  ): Observable<boolean> {
    return this.http.post<boolean>(
      `${environment.apiUrl}/api/investor/registrMarketing`,
      stepPayload,
      {
        params: {
          step,
        },
      },
    );
  }

  public postRegistrationDocument(
    payload: IInvestorRegistrDocumentCreate,
    file: File,
  ): Observable<IAuthUser> {
    const formData = new FormData();
    Object.entries(payload).forEach(i => {
      const key = i[0];
      const val = i[1];
      if (val) {
        formData.append(key, val);
      }
    });
    formData.append('file', file);
    return this.http.post<IAuthUser>(
      `${environment.apiUrl}/api/investor/saveRegistrationDocument`,
      formData,
    );
  }

  public checkRegistrEmailLink(body: {
    code: string;
    email: string;
  }): Observable<IAuthUser> {
    // todo expired time
    return this.http.post<IAuthUser>(
      `${environment.apiUrl}/api/investor/confirmRegistration`,
      body,
    );
  }

  public checkIsEmailAvailable(
    email: string,
  ): Observable<{ available: boolean }> {
    return (
      this.http
        .post<{ available: boolean }>(
          `${environment.apiUrl}/api/investor/checkIsEmailAvailable`,
          { email },
        )
        // mock
        .pipe(delay(environment.production ? 0 : 1000))
    );
  }

  public logout(refreshToken: string): Observable<boolean> {
    return (
      this.http
        .post<boolean>(`${environment.apiUrl}/api/auth/logout`, {
          refreshToken,
        })
        // mock
        .pipe(delay(environment.production ? 0 : 1000))
    );
  }

  public sendRegistrationLink(email: string): Observable<boolean> {
    return this.http.post<boolean>(
      `${environment.apiUrl}/api/investor/sendRegistrLink`,
      { email },
    );
  }

  public checkIsPhoneAvailable(
    phone: string,
  ): Observable<{ isPhoneAvailable: boolean }> {
    return (
      this.http
        .post<{ isPhoneAvailable: boolean }>(
          `${environment.apiUrl}/api/phone/checkIsPhoneAvailable`,
          { phone },
        )
        // mock
        .pipe(delay(environment.production ? 0 : 500))
    );
  }

  public sendSecureCode(body: {
    phone: string;
    phoneCountry: string;
  }): Observable<{ phoneId: string }> {
    return this.http
      .post<{ phoneId: string }>(
        `${environment.apiUrl}/api/phone/sendSecureCode`,
        body,
      )
      .pipe(delay(environment.production ? 0 : 1000));
  }

  public checkSecureCode(body: {
    phoneId: string;
    phone: string;
    phoneCountry: string;
    code: string;
  }): Observable<any> {
    return this.http
      .post<{ phoneId: string }>(
        `${environment.apiUrl}/api/phone/checkSecureCode`,
        body,
      )
      .pipe(delay(environment.production ? 0 : 1000));
  }
}
